<template>
  <div
    class="bg-white p-0 m-0"
  >
    <div class="p-2 d-flex justify-content-between align-items-center">
      <span style="color: #5E5873; font-weight: 500; font-size: 16px">Incidents Report
      </span>
      <!-- <div class="btn btn-primary">
        + Add Record
      </div> -->
    </div>
    <hr class="">
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <div class="d-flex align-items-center">
          <span class="mr-1">
            Show
          </span>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-1">Search</span>
          <b-input-group
            size="md"
          >
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              class="mr-2"
              placeholder="Type to Search"
            />
          </b-input-group>
          <!-- <b-input-group-append> -->
          <div
            class="btn btn-primary pl-2"
            style="width: 90%;"
            @click="showForms"
          >
            <span class="p-2 mt-3">+ Add Record</span>
          </div>
          <!-- </b-input-group-append> -->

        </div>
      </div></b-card-body>
    <!-- table -->
    <b-table
      ref="selectableTable"
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @row-selected="onRowSelected"
      @filtered="onFiltered"
    >
      <template #cell(index)="data">
        <col style="width: 5px;">
        {{ data.index + 1 }}
      </template>
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar :src="data.value" />
          <div class="d-flex flex-column ml-2">
            <span class="font-weight-bolder">
              {{ data.item.personal.name }}

            </span>
            <span style="color: #B9B9C3">
              {{ data.item.personal.email }}
            </span>
          </div>

        </div>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusVariant(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>
      <!-- <template #cell(actions)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25 purpleColor"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteRecord(data.item.id)">
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template> -->
    </b-table>
    <div class="d-flex justify-content-between align-items-center ml-3 mr-3 mb-2">
      <span style="color: #B9B9C3">Showing 1 to {{ perPage }} of {{ items.length }} entries</span>
      <b-pagination
        v-model="currentPage"
        :value="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value)=>props.pageChanged({currentPage:value})"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div>
      <b-modal
        id="modal-delete-confirm"
        centered
        hide-footer
      >
        <p class="my-1 font-weight-bold">
          Confirm to delete this member?
        </p>
        <div class="float-right">
          <b-button
            variant="danger"
            class="btn-wishlist mr-1"
            @click="confirmDel"
          >
            <span>Delete</span>
          </b-button>
          <b-button
            variant="light"
            class="btn-wishlist"
            @click="$bvModal.hide('modal-delete-confirm')"
          >
            <span>Cancel</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCardBody, BFormInput, BButton, BInputGroup, BTable, BAvatar, BBadge, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
// BInputGroupAppend

export default {
  components: {
    BCardBody,
    BInputGroup,
    // BDropdown,
    // BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BFormSelect,
    BPagination,
    BFormInput,
    // BInputGroupAppend,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      deleteRec: 0,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      table: {
        checkedAll: false,
        checkedItems: [],
      },
      modes: ['multi', 'single', 'range'],
      fields: [
        { key: 'name', label: 'name' },
        { key: 'user_id', label: 'User ID', sortable: true },
        { key: 'role', label: 'User Role', sortable: true },
        { key: 'remarks', label: 'Remarks', sortable: true },
        { key: 'status', label: 'Status', sortable: true },

      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      selectMode: 'multi',
      selected: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Inactive: 'light-danger',
        Active: 'light-success',
        Pending: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/analytics/data')
      .then(response => {
        const dashboardData = response.data
        this.items = dashboardData.listTeamMembers.members
        this.totalRows = this.items.length
      })
  },
  methods: {
    showForms() {
      this.$emit('showForms', true)
    },
    deleteRecord(data) {
      console.log(data)
      this.deleteRec = data
      this.$bvModal.show("modal-delete-confirm")
    },
    confirmDel() {
      const timeout = 4000
      this.items.forEach((element, idx) => {
        if (element.id === this.deleteRec) {
          this.items.splice(idx, 1)
        }
      })
      this.$toast(
        {
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Info`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Record successfully deleted!`,
          },
        },
        { timeout },
      )
      this.$bvModal.hide("modal-delete-confirm")
    },
    checkAll(){
      if (this.table.checkedAll){
        this.items.forEach(staff => {
          this.table.checkedItems.push(staff.user_id)
        })
      } else this.table.checkedItems = []
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
