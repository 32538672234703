<template>
  <div>
    <div>
      <div style="height: inherit">
        <div v-if="showReport">
          <div class="card">
            <div class="card-header">
              <span style="color: #5E5873; font-weight: 500px; font-size: 18px;">Employee Details</span>
            </div><hr>
            <div class="p-2 row">
              <div class="col-6">
                <b-form-group
                  label="Employee Name"
                  label-for="employee-name"
                >
                  <b-form-input
                    id="employee-name"
                  />
                </b-form-group>
                <b-form-group
                  label="Employee Number"
                  label-for="employee-number"
                >
                  <b-form-input
                    id="employee-number"
                  />
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  label="Department"
                  label-for="department"
                >
                  <b-form-input
                    id="department"
                  />
                </b-form-group>
                <b-form-group
                  label="Phone Number"
                  label-for="phone-number"
                >
                  <b-form-input
                    id="phone-number"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <span style="color: #5E5873; font-weight: 500px; font-size: 18px;">Description of Incidents</span>
            </div><hr>
            <div class="p-2 row">
              <div class="col-6">
                <b-form-group
                  label="Location"
                  label-for="location"
                >
                  <b-form-input
                    id="location"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Police Notified"
                  class="mb-2"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    :options="optionsPoliceNoti"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                  />
                </b-form-group>
                <b-form-group
                  label="Date of Incident"
                  label-for="incident-date"
                >
                  <b-form-input
                    id="incident-date"
                  />
                </b-form-group>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Incident Type"
                >
                  <!-- <div class="col"> -->
                  <b-form-checkbox-group
                    :options="optionsType"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2a"
                    stacked
                  />
                  <!-- </div> -->
                </b-form-group>
                <b-form-input
                  id="witnesses"
                  disabled
                  placeholder="If you select others, please input related type of incidents"
                />
              </div>
              <div class="col-6 justify-content-space-evenly">
                <b-form-group>
                  <label>Witness</label>
                  <select
                    class="form-control"
                  />
                </b-form-group>
                <b-form-group>
                  <label>Time of Incident</label>
                  <select
                    class="form-control"
                  />
                </b-form-group>
                <b-form-group
                  label="Reported By"
                  label-for="reported-by"
                >

                  <b-form-input
                    id="reported-by"
                  />
                </b-form-group>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Root Cause"
                >
                  <b-form-checkbox-group
                    :options="optionsRoot"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2a"
                    stacked
                  />
                </b-form-group>
                <b-form-input
                  id="witnesses"
                  placeholder="If you select others, please input related root cause of accident"
                />
              </div>
            </div>

            <div class="p-2">
              <label>Incident Details</label>
              <b-form-textarea />
            </div>
          </div>
          <div class="row pl-1">
            <div
              style="width: 170px"
              class="mr-1"
            >
              <b-button
                block
                class="mt-1"
              >
                Save Changes
              </b-button>
            </div>
            <div style="width: 130px;">
              <button
                type="button"
                class="mt-1 btn btn-outline-secondary"
                @click="showReport = false"
              >
                Discard
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <project-member
            class="col-12 mt-3"
            @showForms="showForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput, BButton, BFormTextarea, BFormCheckboxGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import ProjectMember from '@/views/pages/dashboard/analytic/component/ProjectMember.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormRadioGroup,
    ProjectMember,
    // vSelect,
  },
  data() {
    return {
      showReport: false,
      optionsPoliceNoti: [
        { text: 'Yes', value: 'first' },
        { text: 'No', value: 'second' },
      ],
      optionsType: [
        { text: 'Injury - First Aid', value: 'orange' },
        { text: 'Injury - Medical / Emergency Treatment', value: 'apple' },
        { text: 'Property Damage', value: 'pineapple' },
        { text: 'Equipment Failure', value: 'grape' },
        { text: 'Theft', value: 'pineapple' },
        { text: 'Others', value: 'grape' },
      ],
      optionsRoot: [
        { text: 'Behavior', value: 'orange' },
        { text: 'Procedure', value: 'apple' },
        { text: 'Equipment', value: 'pineapple' },
        { text: 'Conditions', value: 'grape' },
        { text: 'PPE', value: 'pineapple' },
        { text: 'Others', value: 'grape' },
      ],
    }
  },
  methods: {
    showForm(val) {
      console.log(val)
      this.showReport = val
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
